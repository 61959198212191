import CommonCrypto from "./common";
// import QuickCrypto from "react-native-quick-crypto";
// const {
// 	createSign,
// 	createVerify
// } = QuickCrypto;

export default class WebKit {
	/**
	 * 
	 * @param {string} publicKey PEM-Formatted RSA Public encryption key
	 * @param {string} privateKey PEM-Formatted RSA Private decryption key
	 * @param {boolean} ignorePrivate Whether to ignore the private key
	 */
	constructor(publicKey, privateKey, ignorePrivate) {
		const pubPemHeader = "-----BEGIN PUBLIC KEY-----";
		const pubPemFooter = "-----END PUBLIC KEY-----";
		const privPemHeader = "-----BEGIN PRIVATE KEY-----";
		const privPemFooter = "-----END PRIVATE KEY-----";

		let pubB64String = publicKey.replace(pubPemHeader, "").replace(pubPemFooter, "");
		pubB64String = pubB64String.replace(/\s+/g, "");
		const pubBinDerString = atob(pubB64String);
		const pubBinDer = new Uint8Array(pubBinDerString.length);
		for (let i = 0; i < pubBinDerString.length; i++) {
			pubBinDer[i] = pubBinDerString.charCodeAt(i);
		}
		this.publicKey = pubBinDer.buffer;

		this.ignorePrivate = ignorePrivate;
		if (ignorePrivate) return;
		let privB64String = privateKey.replace(privPemHeader, "").replace(privPemFooter, "");
		privB64String = privB64String.replace(/\s+/g, "");
		const privBinDerString = atob(privB64String);
		const privBinDer = new Uint8Array(privBinDerString.length);
		for (let i = 0; i < privBinDerString.length; i++) {
			privBinDer[i] = privBinDerString.charCodeAt(i);
		}
		this.privateKey = privBinDer.buffer;
	}

	/**
	 * Import provided public/private keys into usable `CryptoKey`s
	 */
	async importKeys() {
		this.JWTVerifyKey = await crypto.subtle.importKey(
			"spki",
			this.publicKey,
			{
				name: "RSASSA-PKCS1-v1_5",
				hash: "SHA-256"
			},
			true,
			["verify"]
		);
		
		this.publicKey = await crypto.subtle.importKey(
			"spki",
			this.publicKey,
			{
				name: "RSA-OAEP",
				hash: "SHA-256"
			},
			true,
			["encrypt"]
		);
		
		if (this.ignorePrivate) return;

		this.JWTSignKey = await crypto.subtle.importKey(
			"pkcs8",
			this.privateKey,
			{
				name: "RSASSA-PKCS1-v1_5",
				hash: "SHA-256"
			},
			true,
			["sign"]
		);

		this.privateKey = await crypto.subtle.importKey(
			"pkcs8",
			this.privateKey,
			{
				name: "RSA-OAEP",
				hash: "SHA-256"
			},
			true,
			["decrypt"]
		);
	}

	/**
	 * 
	 * @param {string} plaintext 
	 * @returns {Promise<{ buffer: Buffer, hex: string }>} The encrypted plaintext
	 */
	async encryptWithPublicKey(plaintext) {
		//* console.log(plaintext.length * 8)
		const ciphertextBuffer = await crypto.subtle.encrypt(
			{
				name: "RSA-OAEP"
			},
			this.publicKey,
			Buffer.from(plaintext)
		);
		const ciphertext = [...new Uint8Array(ciphertextBuffer)].map(b => b.toString(16).padStart(2, "0")).join('');

		return {
			buffer: ciphertextBuffer,
			hex: ciphertext
		};
	}

	/**
	 * @returns {Promise<{ publicKey: string, privateKey: string }>} The RSA keypair
	 */
	static async generateRSAKeypair() {
		const keyPair = await crypto.subtle.generateKey(
			{
				name: 'RSA-OAEP',
				modulusLength: 4096,
				publicExponent: new Uint8Array([1, 0, 1]),
				hash: 'SHA-256'
			},
			true,
			['encrypt', 'decrypt']
		);

		const publicKeyDer = await crypto.subtle.exportKey('spki', keyPair.publicKey);
		const privateKeyDer = await crypto.subtle.exportKey('pkcs8', keyPair.privateKey);

		const pubB64 = Buffer.from(new Uint8Array(publicKeyDer)).toString("base64");
		const privB64 = Buffer.from(new Uint8Array(privateKeyDer)).toString("base64");

		return {
			publicKey: `-----BEGIN PUBLIC KEY-----\n${pubB64}\n-----END PUBLIC KEY-----`,
			privateKey: `-----BEGIN PRIVATE KEY-----\n${privB64}\n-----END PRIVATE KEY-----`
		};
	}

	/**
	 * @param {Buffer} ciphertext The encrypted plaintext's buffer
	 * @param {CryptoKey} key The AES-CBC key
	 * @param {Buffer} iv The IV used for encryption
	 * @returns {Promise<string>} The decrypted ciphertext
	 */
	static async decryptUsingAES_CBC(ciphertext, cryptokey, iv) {
		const plaintextBuffer = await crypto.subtle.decrypt(
			{
				name: "AES-CBC",
				iv
			},
			cryptokey,
			ciphertext
		);
		const plaintext = new TextDecoder("utf-8").decode(new Uint8Array(plaintextBuffer));

		return plaintext.toString("utf-8");
	}

	/**
	 * @param {string} plaintext The plaintext to be encrypted
	 * @param {CryptoKey} cryptokey The AES-CBC key
	 * @returns {Promise<{ iv: string, ciphertext: string }>} The encrypted plaintext as well as the nonce used for encryption
	 */
	static async encryptUsingAES_CBC(plaintext, cryptokey) {
		const iv = await crypto.getRandomValues(new Uint8Array(16));
		const ciphertextBuffer = await crypto.subtle.encrypt(
			{
				name: "AES-CBC",
				iv
			},
			cryptokey,
			Buffer.from(plaintext)
		);
		const ciphertext = [...new Uint8Array(ciphertextBuffer)].map(b => b.toString(16).padStart(2, "0")).join('');

		const binary = String.fromCharCode.apply(null, new Uint8Array(iv));
		const b64_IV = btoa(binary);

		return { iv: b64_IV, ciphertext }
	}

	/**
	 * 
	 * @param {Buffer} ciphertext The encrypted plaintext's Buffer
	 * @returns {Promise<string>} The decrypted ciphertext
	 */
	async decryptWithPrivateKey(ciphertext) {
		const decryptedDataBuffer = await crypto.subtle.decrypt(
			{
				name: "RSA-OAEP"
			},
			this.privateKey,
			ciphertext
		);
		const webDecryptedData = new TextDecoder("utf-8").decode(new Uint8Array(decryptedDataBuffer));

		return webDecryptedData.toString("utf-8");
	}

	/**
	 * Safely b64-decode by replacing non-url compatible chars with base64 standard chars
	 *
	 * @param {string} input The base64url encoded input string to decode
	 * @return {string} The decoded string
	 */
	static base64UrlDecode(input) {
		input = input.replace(/-/g, '+').replace(/_/g, '/');
        const pad = input.length % 4 === 0 ? '' : '='.repeat(4 - (input.length % 4));
        return atob(input + pad);
	};

	/**
	 * Retrieves the payload from a JSON Web Token (JWT).
	 *
	 * @param {`${header}.${payload}.${signature}`} jwt - The JWT from which to extract the payload.
	 * @return {{ iss: "mawjood.proman4713gamedev.com", aud: "mawjood.proman4713gamedev.com", sub: string, exp: number, iat: number, nbf: number, email: string, name: string, authLevel: number }} The payload of the JWT as a parsed JSON object.
	 * @throws {Error} If the JWT does not have exactly three parts.
	 */
	static getJwtPayload(jwt) {
		const tokenParts = jwt.split('.');
		if (tokenParts.length !== 3) {
			throw new Error('Invalid JWT: The token must have three parts');
		}

		const payloadInBase64Url = tokenParts[1];
		const decodedPayload = this.base64UrlDecode(payloadInBase64Url);

		return JSON.parse(decodedPayload);
	};

	// /**
	//  * 
	//  * @param {string} plaintext 
	//  * @returns {Promise<Buffer | string>} The resulting signature
	//  */
	// signWithPrivateKey(plaintext) {
	// 	return createSign("rsa-sha256").update(plaintext).sign(this.privateKey, "hex");
	// }

	// /**
	//  * 
	//  * @param {string} data 
	//  * @param {Buffer | string} signature 
	//  * @returns {Boolean} If the signature is valid
	//  */
	// verifyWithPublicKey(data, signature) {
	// 	return createVerify("rsa-sha256").update(data).verify(this.publicKey, signature, "hex");
	// }

	/**
	 * 
	 * @param {string} ciphertext The encrypted plaintext
	 * @returns {ArrayBuffer} The ciphertext's Array Buffer
	 */
	retrieveBufferFromCipherHex(ciphertext) {
		return new Uint8Array(ciphertext.match(/.{1,2}/g).map(byte => parseInt(byte, 16))).buffer;
	}

	/**
	 * 
	 * @param {ArrayBuffer} retrievedBuffer The buffer retrieved from the ciphertext
	 * @param {Buffer} ciphertext The actual ciphertext buffer
	 * @returns {Boolean} Whether the retrieved buffer matches the ciphertext
	 */
	compareCipherBuffers(retrievedBuffer, ciphertext) {
		return CommonCrypto.compareArrayBuffers(
			retrievedBuffer,
			ciphertext
		)
	}
}