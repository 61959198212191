import React, { useContext, useEffect, useMemo, useState } from "react";
import FlatList from "../../Components/Custom/FlatList";
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import Card from "../../Components/Utilities/Card";

export default function LogViewerScreen() {
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);
	const [logArray, setLogArray] = useState([]);
	const [lastTimestamp, setLastTimestamp] = useState(0);

	const renderItem = ({ item, index }) => {
		let content = "";

		switch (item["type"]) {
			case "Attendance Submission":
				content = `On Period ${item["data"]["period"]}, ${item["data"]["error"] === false ? `${item["data"]["presentStudents"].replace(/\**/g, "")} students present` : `something went wrong: ${item["data"]["message"]}`}`
				if (item["data"]["error"] === false) {
					content = (
						<>
							{content.split(",")[0] + ","}
							<strong style={{ fontFamily: "monospace", fontSize: 15 }}>{content.split(",")[1].split("out")[0]}</strong>
							{content.split(",")[1].split("out")[1]}
						</>
					)
				}
				break;
			case "Class Creation":
				content = (
					<>
						{item["data"]["error"] === false
							? <>
								<strong>{item["data"]["numOfStudents"]}</strong> Total Students
							</>
							: <>
								Something went wrong: {item["data"]["message"]}
							</>}
					</>
				)
				break;
			case "Class Editing":
				content = (
					<>
						{item["data"]["error"] === false
							? <>
								<strong>{item["data"]["numOfStudents"]}</strong> Total Students
							</>
							: <>
								Something went wrong: {item["data"]["message"]}
							</>}
					</>
				)
				break;
			default:
				break;
		}

		return (<Card
			additionalStyles={{
				[index === 0 ? "marginTop" : ""]: 10,
				marginBottom: 30,
				marginLeft: "auto",
				marginRight: "auto",
				width: "90%",
				boxShadow: `0px 0px 15px 0px ${item["data"]["error"] === false ? colors().success : colors().danger}`
			}}
			title={`${item["type"]} for ${item["data"]["class"]}`}
			content={content}
			footer={`${new Date(item.timestamp).toLocaleDateString("en-GB", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} ${new Date(item.timestamp).toLocaleTimeString()}`}
		/>)
	};
	
	const logStyles = useMemo(() => ({
		container: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: colors().screen,
			justifyContent: "center",
			alignContent: "center",
			height: "100vh"
		}
	}), [dark, locale]);

	useEffect(() => {
		const getInitialLogs = async () => {
			await fetch(`${process.env.REACT_APP_API_URL}/logs/fetchAll`, {
				headers: {
					apikey: "mawjoodStorage-Mr8Y9rInYA9yHr0lFYGT"
				}
			})
				.then(r => r.json())
				.then(dat => setLogArray(dat["documents"]))
				.catch(e => console.error(e));

			setLastTimestamp(Date.now());

			setInterval(async () => {
				await fetch(`${process.env.REACT_APP_API_URL}/logs/pollLatest`, {
					headers: {
						apikey: "mawjoodStorage-Mr8Y9rInYA9yHr0lFYGT",
						lastTimestamp: lastTimestamp.toString()
					}
				})
					.then(r => r.json())
					.then(dat => {
						if (dat["documents"].length === 0) return;
						setLogArray(dat["documents"]);
						setLastTimestamp(Date.now());
					})
					.catch(e => console.error(e));
			}, 30 * 1000);
		}

		getInitialLogs()
	}, [])

	return (
		<>
			<div style={logStyles.container}>
				<FlatList
					data={logArray}
					renderItem={renderItem}
					keyExtractor={(item) => item.timestamp}
					contentContainerStyle={{ paddingTop: 122 }}
				/>
			</div>
		</>
	);
}