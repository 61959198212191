import "../Auth/LoginScreen.css";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import { useParams } from "react-router-dom";
import WebsiteHeader from "../../Components/WebsiteHeader";
import TeacherAccScreen from "../Admin/TeacherAccScreen";
import LogViewerScreen from "../Admin/LogViewerScreen";

const getChildComponent = (activeTab) => {
	// }
	return (
		<>
			{activeTab === 0 && <h2>Under Const.</h2>}
			{activeTab === 1 && <h2>ClassData</h2>}
			{activeTab === 2 && <LogViewerScreen />}
			{activeTab === 3 && <h2>Data Export</h2>}
		</>
	)
}

export default function SettingsScreen() {
	const params = useParams();
	useEffect(() => {
		document.title = `Mawjood | ${params.accountName}`;
	}, [params])

	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);
	const [ activeTab, setActiveTab ] = useState(0);
	const [ drawerOpen, setDrawerOpen ] = useState(false);

	const getSchoolName = () => {
		switch (locale) {
			case "en":
				return process.env.REACT_APP_SCHOOL_NAME_EN;
			case "ar":
				return process.env.REACT_APP_SCHOOL_NAME_AR;
			case "de":
				return process.env.REACT_APP_SCHOOL_NAME_DE;
			case "fr":
				return process.env.REACT_APP_SCHOOL_NAME_FR;
			default:
				return process.env.REACT_APP_SCHOOL_NAME_EN;
		}
	}

	const changeActiveTab = (tab) => {
		setActiveTab(tab);
		setDrawerOpen(false);
	}
	
	let loginStyles = useMemo(() => ({
		container: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: colors().screen,
			justifyContent: "center",
			alignContent: "center",
			height: "100vh"
		}
	}), [dark, locale]);

	return (
		<>
			<WebsiteHeader
				showAppName={false}
				headerTitle={getSchoolName()}
				drawer
				drawerTitle="Settings"
				drawerOpen={drawerOpen}
				setDrawerOpen={setDrawerOpen}
				drawerItems={[{
						text: "Teacher Accounts",
						onClick: () => {
							changeActiveTab(0);
						},
						active: activeTab === 0
					}, {
						text: "ClassData",
						onClick: () => {
							changeActiveTab(1);
						},
						active: activeTab === 1
					}, {
						text: "Logs",
						onClick: () => {
							changeActiveTab(2);
						},
						active: activeTab === 2
					}, {
						text: "Data Export",
						onClick: () => {
							changeActiveTab(3);
						},
						active: activeTab === 3
					}
				]}
				forceBG={activeTab !== 2}
			/>
			<div style={loginStyles.container}>
				{getChildComponent(activeTab)}
			</div>
		</>
	);
}