import "./LoginScreen.css";
import React, { useContext, useMemo, useRef, useState } from "react";
import { AppThemeContext } from "../../contexts/colors";
import { localeContext } from "../../contexts/localeManagement";
import { AuthContext } from "../../contexts/accountManagement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import PropagateLoader from "react-spinners/PropagateLoader";
import { faAt } from "@fortawesome/free-solid-svg-icons/faAt";
import { useStateCallback } from "../../utils/stateCallback";

export default function LoginScreen() {
	const [email, setEmail] = useState('');
	const [signinKey, setSignInKey] = useState('Email');

	const [password, setPassword] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [wrong, setWrong] = useState(false);
	const [wrongTxt, setWrongTxt] = useState(`Incorrect ${signinKey} or Password`);

	const { preLoggedIn, logIn, statusText } = useContext(AuthContext);
	const {
		dark,
		colors
	} = useContext(AppThemeContext);
	const { locale, appText } = useContext(localeContext);

	const [ statusTextOpacity, setStatusTextOpacity ] = useStateCallback(0);
	const [statusTextLocal, setStatusTextLocal] = useStateCallback("");
	
	let loginStyles = useMemo(() => ({
		container: {
			display: "flex",
			flex: 1,
			flexDirection: "column",
			backgroundColor: colors().screen,
			justifyContent: "center",
			alignContent: "center",
			height: "100vh"
		},
		title: {
			color: dark ? colors().constantWhite : "#379634",
			textAlign: "center",
			fontSize: 40,
			fontFamily: locale === "ar" ? "ArabicMainBold" : "Ubuntu",
			fontWeight: locale === "ar"
				? "regular"
				: "bold",
			marginBottom: 0
		},
		form: {
			marginTop: 20
		},
		input: {
			backgroundColor: colors().success,
			width: "90%",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: 15,
			padding: 10,
			paddingTop: locale === "ar" ? 0 : 10,
			paddingBottom: locale === "ar" ? 0 : 10,
			[locale === "ar" ? "paddingRight" : "paddingLeft"]: 12,
			fontFamily: locale === "ar" ? "ArabicMainNormal" : "Ubuntu",
			fontWeight: locale === "ar"
				? "regular"
				: "bold",
			fontSize: 22,
			color: "black",
			textAlign: locale === "ar" ? "right" : "left",
			border: "none",
			outline: "none"
		},
		inputShort: {
			height: 50 / 2,
			marginBottom: 20 / 2
		},
		inputParagraph: {
			height: 100 / 2
		},
		inputContainer: {
			display: "flex",
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'center',
			backgroundColor: colors().success,
			borderRadius: 15,
			paddingLeft: 14,
			paddingRight: 14,
			width: "30%",
			marginLeft: "auto",
			marginRight: "auto"
		},
		btn: {
			padding: 10,
			backgroundColor: dark ? colors().constantWhite : colors().lightButton,
			width: "30%",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: 15,
			height: 52,

			display: "flex",
			justifyContent: "center",
			alignContent: "center",
			alignItems: "center",
			flexDirection: "row",
			border: "none",
			outline: "none",

			transition: "300ms all ease"
		},
		btnText: {
			color: dark ? colors().mawjoodMain : colors().listItem,
			fontSize: 22,
			textAlign: "center",
			fontFamily: locale === "ar" ? "ArabicMainBold" : "Ubuntu",
			fontWeight: locale === "ar"
				? "regular"
				: "bold"
		},
		error: {
			display: "flex",
			flexDirection: "column",
			backgroundColor: colors().danger + "A2",
			paddingLeft: 20,
			paddingRight: 20,
			width: "30%",
			marginLeft: "auto",
			marginRight: "auto",
			borderRadius: 20,
			height: 80,
			justifyContent: "center",
			borderColor: colors().danger,
			borderWidth: 2,
			marginTop: 10
		},
		errorTxt: {
			color: colors().constantWhite,
			fontSize: 17,
			fontWeight: "bold",
			textAlign: "center",
			fontFamily: "Ubuntu Mono"
		}
	}), [dark, locale]);

	/**
	 * @type {React.LegacyRef<HTMLInputElement>}
	 */
	const passInput = useRef(null);

	const toggleShowPassword = () => {
		setShowPassword(!showPassword)
	}

	const handleLogin = async () => {
		logIn(email, password, signinKey)
			.then(res => {
				if (res.success) {
					setWrong(false)
				} else {
					setWrong(true);
					setWrongTxt(res.message);
				}
			})
	}

	React.useEffect(() => {
		//* console.log(statusText)
		console.log(statusText, statusTextLocal)
		if (statusTextLocal === "") {
			console.log("no status text, setting opacity");
			setStatusTextOpacity(1, () => {
				console.log("set opacity to 1");
				setStatusTextLocal(statusText)
			});
			return;
		}

		console.log("status text exists, setting opacity to 0");
		setStatusTextOpacity(0, () => {
			console.log("set status text local");
			setStatusTextLocal(statusText, () => {
				console.log("set opacity to 1");
				setStatusTextOpacity(1);
			});
		})
	}, [statusText]);

	return (
		<>
			<div style={loginStyles.container}>
				<h1 style={loginStyles.title}>{appText["Login"]}</h1>
				<div style={{ ...loginStyles.error, ...{
					display: wrong ? "flex" : "none"
				} }}>
					<p style={loginStyles.errorTxt}>{appText[wrongTxt] || wrongTxt}</p>
				</div>
				<div style={loginStyles.form}>
					<div style={{ ...loginStyles.inputContainer, ...{
						marginBottom: 20
					} }}>
						<input
							style={ {...loginStyles.input, ...loginStyles.inputShort, ...{
								marginBottom: 0,
								flex: 1,
								marginLeft: -15
							}} }
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder={appText[signinKey]}
							type={signinKey === "Username" ? "text" : "email"}
							enterKeyHint="next"
							onKeyDown={e => e.key === "Enter" ? passInput.current.focus() : null}
							disabled={preLoggedIn}
						/>
						<FontAwesomeIcon
							icon={signinKey === "Username" ? faAt : faEnvelope}
							size="xl"
							color={colors().primary}
							style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
							onMouseDown={e => {
								e.preventDefault();
								e.stopPropagation();
								setSignInKey(signinKey === "Username" ? "Email" : "Username");
								setEmail("")
								setWrong(false)
							}}
						/>
					</div>
					<div style={loginStyles.inputContainer}>
						<input
							style={ {...loginStyles.input, ...loginStyles.inputShort, ...{
								marginBottom: 0,
								flex: 1,
								marginLeft: -15
							}} }
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							placeholder={appText["Password"]}
							type={showPassword ? "text" : "password"}
							autoCapitalize="none"
							autoComplete="current-password"
							autoCorrect="none"
							ref={passInput}
							onKeyDown={e => e.key === "Enter" && password && email ? handleLogin() : null}
							disabled={preLoggedIn}
						/>
						{showPassword
							? <IoMdEyeOff
								size={24}
								color={colors().primary}
								style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
								onMouseDown={e => {
									e.preventDefault();
									e.stopPropagation();
									toggleShowPassword();
								}}
							/>
							: <IoMdEye
								size={24}
								color={colors().primary}
								style={{ marginLeft: "auto", marginRight: "auto", cursor: "pointer" }}
								onMouseDown={e => {
									e.preventDefault();
									e.stopPropagation();
									toggleShowPassword();
								}}
							/>}
					</div>
				</div>
				<button
					className="login-btn"
					style={{ ...loginStyles.btn, ...{
						marginTop: 30,
						backgroundColor: !((
							signinKey === "Email" &&
								// eslint-disable-next-line no-useless-escape
								!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
							)
							|| (signinKey === "Username" && !email)
							|| !password
							|| preLoggedIn)
							? dark ? colors().constantWhite : colors().lightButton
							: dark ? colors().constantWhite + "AA" : colors().lightButton + "AA",
						cursor: "pointer"
					} }}
					onClick={handleLogin}
					disabled={(
						signinKey === "Email" &&
						// eslint-disable-next-line no-useless-escape
						!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
					)
						|| (signinKey === "Username" && !email)
						|| !password
						|| preLoggedIn}
				>
					<p style={{...loginStyles.btnText, ...{
						color: !((
							signinKey === "Email" &&
								// eslint-disable-next-line no-useless-escape
								!email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
							)
							|| (signinKey === "Username" && !email)
							|| !password
							|| preLoggedIn)
							? dark ? colors().mawjoodMain : colors().listItem
							: dark ? colors().mawjoodMain + "AA" : colors().listItem + "AA",
						marginTop: "auto"
					}}}>{preLoggedIn
						? <PropagateLoader size={10} color={dark ? colors().mawjoodMain + "AA" : colors().listItem + "AA"} cssOverride={{ textAlign: "center" }} />
						: appText["Login"].replace("تسجيل", "سجِّل")}
					</p>
				</button>
				<p
					style={{
						fontSize: 20,
						color: colors().mawjoodOther,
						textAlign: "center",
						fontFamily: "monospace",
						fontWeight: "bold",
						marginBottom: -45,
						marginTop: 20,
						opacity: statusTextOpacity,
						transition: "200ms all ease"
					}}
				>
					{appText[statusTextLocal] || statusTextLocal}
				</p>
			</div>
		</>
	);
}