import "./Homepage.css";
import WebsiteHeader from "../../Components/WebsiteHeader";
import FULL_LOGO from '../../Assets/Images/full_logo.png';

export default function Homepage() {
	return (
		<>
			<WebsiteHeader />
			{/* <section className="homepage-section" style={{ height: "100vh" }}>
				<div className="background-wrapper" style={{ backgroundImage: `url(${FULL_LOGO})`, backgroundSize: "cover", backgroundPosition: "center" }}></div>
				<div className="sectionContainer">
					<h1>jelloas<br />sa<br />sa<br />sa</h1>
				</div>
			</section>
			<section className="homepage-section">
				<div className="sectionContainer">
					<h1>jelloas<br />sa<br />sa<br />sa</h1>
				</div>
			</section>
			<section className="homepage-section">
				<div className="sectionContainer">
					<h1>jelloas<br />sa<br />sa<br />sa</h1>
				</div>
			</section>
			<section className="homepage-section">
				<div className="sectionContainer">
					<h1>jelloas<br />sa<br />sa<br />sa</h1>
				</div>
			</section> */}
			<iframe src="https://mawjood.proman4713gamedev.com" title='homepage' width="100%" height="100%" frameborder="0"></iframe>
		</>
	);
}