import {
	createContext,
	useCallback,
	useEffect,
	useState
} from "react";

export const AppThemeContext = createContext()

export function AppThemeProvider({
	children
}) {
	const [dark, setDark] = useState(false)
	const [theme, setTheme] = useState("light")

	/**
	 * A function to asynchronously get the application theme.
	 *
	 * @return {boolean} the application theme
	 */
	const getApplicationTheme = async () => {
		try {
			// Try to get the theme from SecureStore
			const themeSetting = await localStorage.getItem('theme');

			if (themeSetting !== null) {
				// If there's a theme set in localStorage, use it
				setTheme(themeSetting);
				return themeSetting === "dark";
			} else {
				// If the theme is 'automatic' or not set, use the device's color scheme
				setTheme("light")
				return false;
			}
		} catch (error) {
			//* console.error('Failed to fetch the theme from localeStorage:', error);
			// Default to light theme in case of any error
			return false;
		}
	}

	/**
	 * Set the runtime theme based on the application theme.
	 *
	 * @return {Promise<void>} 
	 */
	const setRuntimeTheme = useCallback(async () => {
		const theme = await getApplicationTheme();
		
		setDark(theme);
	}, [])

	useEffect(() => {
		setRuntimeTheme()
	}, [setRuntimeTheme])

	/**
	 * Returns an object containing color values based on the value of `dark` variable.
	 *
	 * @returns {object} An object with color values
	 */
	const colors = () => {
		return {
			primary: dark ? "#2e2d2d" : "#1d2d44",
			secondary: dark ? "#575757" : "#2b3d57",
			tertiary: dark ? "#d3d3d3" : "#525252",
			screen: dark ? "#1f1f1f" : "#e3e3e3",

			mawjoodMain: "#1d2d44",
			mawjoodOther: "#78d693",

			gray: "#5b5b5b",

			constantWhite: "#ffffff",
			dynamicWhite: dark ? "#ffffff" : "#000000",

			constantBlack: "#000000",
			dynamicBlack: dark ? "#000000" : "#ffffff",

			danger: "#f03c3c",
			warning: "#f0b90b",
			success: dark ? "#58A4B0" : "#78d693",

			listItem: dark ? "#2e2d2d" : "#f4f8f8",
			thumb: dark ? "#58A4B0" : "#D8DBE2",
			track: dark ? null : "#737373",
			placeholder: "#ffffffee",
			lightButton: "#0A3200"
		}
	}

	return (
		<AppThemeContext.Provider value={
			{
				dark,
				getApplicationTheme,
				setRuntimeTheme,
				colors,
				theme
			}
		}>
			{children}
		</AppThemeContext.Provider>
	)
}