export default class CommonCrypto {
	/**
	 * @param {ArrayBuffer} a
	 * @param {ArrayBuffer} b
	 * @returns {boolean}
	 */
	static compareArrayBuffers(a, b) {
		if (a.byteLength !== b.byteLength) return false;
		const viewA = new Uint8Array(a);
		const viewB = new Uint8Array(b);
		for (let i = 0; i < viewA.byteLength; i++) {
			if (viewA[i] !== viewB[i]) {
				return false;
			}
		}
		return true;
	};

	static RSAPublicKey = `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3IBf87nuZIcAeIJbkKGo
+JstfQ3SuacQmRl8MQzEYuYSgF6dQ9CUNg0PnpdUNk0Z7ZC1K5/+M0iOm71MclCw
98OIvCheSzKZ/U12h0gUL7bV9tCH9SC1DGqq2iqsgDGdpXq+Sr7iZMmgv50G2N+Q
Ky0V7WszpMjBHysNxJRlROpBMwWlADL2WmBGgIdQny8CZADK7/v1jrH4ptOFUMfu
Dyuv0kYAJKx/U8cuz8xlOEjZ/rEWNjPexVy9XMzJnLHcWrQAAcOcoVayMELe68ld
DlUvI7GgdFYMWtkhdF94rAUpWaEbtxqrpuP7pmFjo9FBRQp3nv6vMUZKa9us/ptx
7ax2J3qiwm1R+pIgzMvpwyoWofksIYE8EqgEIgfCtKOnA8zSVy/wz4MS6/VIsURX
+8e6smzoqOBgoLTo8rgLseZQEF/JeotREGL5pABcrEqG2QK+0FmRK36X3mwTS4ws
6/ur3tR8DvnIK63KGbcrBLO/TsnlmyJhP8EYs4+n3hyJV3vsm3NTy+QLDyK0ua9v
6GO6DbyVXfazzTkxZ7pATHBfjS5sl8zxE5CZ4sZVYEyuyD7ul/odMqoUjJa4OhcO
7vOl5LPqvFsE9fQS26Sk5Q39IIhuzfPKpWOtIXbHhTno4QlL9LUzxfnyMTOwS7vM
rDxkF4G0i6Q+gXva0DybStkCAwEAAQ==
-----END PUBLIC KEY-----`
}