import {
	createContext,
	useState
} from "react";

export const localeContext = createContext();

export function LocaleProvider({
	children
}) {
	const [locale, setLocale] = useState("en");
	//const memoizedLocale = useMemo(() => locale, [locale]);

	const getApplicationLocale = async () => {
		try {
			// Try to get the theme from SecureStore
			const localeSetting = await localStorage.getItem('locale');

			if (localeSetting !== null) {
				setLocale(localeSetting);
			}
		} catch (e) {
			return;
		}
	};

	const setApplicationLocale = async (locale) => {
		try {
			await localStorage.setItem('locale', locale);
			setLocale(locale);
		} catch (e) {
			return;
		}
	}

	const appText = (() => {
		//* console.log(locale)
		let text = {
			"Home": "HOME",
			"About": "ABOUT",
			"Credits": "CREDITS",
			"Add Your School": "ADD YOUR SCHOOL",

			"Login": "Login",
			"Settings": "Settings",
			"Sign Up": "Sign Up",
			"Log Out": "Log Out",

			"Username": "Username",
			"Email": "Email",
			"Password": "Password",
		}

		switch (locale) {
			case "ar":
				text = {
					"Home": "الرئيسية",
					"About": "عن",
					"Credits": "التفاصيل",
					"Add Your School": "إضافة مدرسة",

					"Login": "تسجيل الدخول",
					"Settings": "الاعدادات",
					"Sign Up": "إنشاء حساب",
					"Log Out": "تسجيل الخروج",

					"Username": "اسم المستخدم",
					"Email": "البريد الالكتروني",
					"Password": "كلمة المرور",
				}
				break;

			case "de":
				text = {
					"Home": "Startseite",
					"About": "Über",
					"Credits": "Credits",
					"Add Your School": "Über die Schule registrieren",

					"Login": "Anmeldung",
					"Settings": "Einstellungen",
					"Sign Up": "Registrierung",
					"Log Out": "Ausloggen",

					"Username": "Benutzername",
					"Email": "E-Mail",
					"Password": "Passwort",
				}
				break;
			
			default:
				break;
		}

		return text;
	})();

	return (
		<localeContext.Provider
			value={{
				locale,
				getApplicationLocale,
				setApplicationLocale,
				appText
			}}
		>
			{children}
		</localeContext.Provider>
	);
}